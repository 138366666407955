import React from "react";
import logo from "logo.jpg";
import styled from "styled-components";

function Banner({className}: any){
  return(
    <div className={className}>
      <img src={logo} />
    </div>
  )
}

const StyledBanner = styled(Banner)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
  padding: 2rem 0;
  img {
    width: 100%;
    max-width: 30rem;
    height: auto;
  }
`

export default StyledBanner;



