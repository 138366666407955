import React from 'react';
import Banner from 'components/Banner';
import BlogFeed from 'components/BlogFeed';

function Home(){
  return(
    <div>
      <Banner/>
      <BlogFeed/>
    </div>
  );
}

export default Home;
