import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createGlobalStyle } from 'styled-components'
import { COLORS, SPACING } from 'global/constants';

const GlobalStyle = createGlobalStyle`
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
html, body {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    background: #101010;
    color: ${COLORS.MEDIUM_GREY};
    line-height: ${SPACING.XLARGE};
  }
  p {
    margin-bottom: ${SPACING.XSMALL};
    margin-top: 0;
    white-space: pre-line;
  }

  h1, h2 {
    text-transform: uppercase;
    color: ${COLORS.LIGHT_GREY};
    margin-bottom: ${SPACING.XSMALL};
  }

  h3, h4 {
    color: ${COLORS.LIGHT_GREY};
    margin-bottom: ${SPACING.XSMALL};
  }

  a, a:visited {
    color: ${COLORS.MEDIUM_GREY};
    text-decoration: none;
  }

  a:hover {
    color: ${COLORS.LIGHT_GREY}
  }

  li {
    padding-bottom: ${SPACING.XSMALL}
  }
`

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
