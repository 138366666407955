import React from 'react';
import styled from 'styled-components';

function Wrapper(props: any){
  const { children } = props;
  return(
    <StyledOuterWrapper>
      <StyledInnerWrapper>
        {children}
      </StyledInnerWrapper>
    </StyledOuterWrapper>
  )
}

const StyledOuterWrapper = styled.div`
  width: 100%;
  display:flex;
  justify-content: center;
`

const StyledInnerWrapper = styled.div`
  width: 100%;
  max-width: 850px;
`
export default Wrapper;
