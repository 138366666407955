import { css } from 'styled-components';
export const BASE_FONT_SIZE = '16px';
export const BASE_FONT_FAMILY = 'sans-serif';
export const PAGE_WRAPPER_WIDTH = '1366px';
export const RESIZE_DEBOUNCE = 300;
export const CHECKBOX_SIZE = '1.2rem';

export enum FONT_FAMILY {
  DEFAULT = 'sans-serif'
}

export enum TEXT_ELEMENTS {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
  P = 'p',
  SPAN = 'span'
}

export enum TEXT_DECORATION {
  NONE = 'none',
  UNDERLINE = 'underline'
}

export enum FONT_WEIGHT {
  BOLD = 'bold',
  NORMAL = 'normal'
}

export enum SCREEN_SIZES {
  SMALL = '576px',
  MEDIUM = '768px',
  LARGE = '992px',
  XLARGE = '1200px'
}

export enum WIDTHS {
  QUARTER = '25%',
  THIRD = '33%',
  HALF = '50%',
  TWO_THIRDS = '66.666%',
  THREE_QUARTERS = '75%',
  FULL = '100%'
}

export enum DISPLAY {
  FLEX = 'flex',
  BLOCK = 'block'
}

export enum ALIGN_CONTENT {
  CENTER = 'center',
  FLEX_START = 'flex-start',
  FLEX_END = 'flex-end'
}

export enum VERTICAL_ALIGN {
  MIDDLE = 'middle'
}

export enum FLEX_DIRECTION {
  ROW = 'row',
  COLUMN = 'column'
}

export enum COLORS {
  PRIMARY = '#4848f0',
  SECONDARY = '#555',
  RED = '#D8000C',
  LIGHT_RED = '#FFBABA',
  WHITE = '#fff',
  LIGHT_GREY = '#aaa',
  DARK_GREY = '#1f1f1f',
  MEDIUM_GREY = '#7a7a7a',
  BLACK = '#000',
  TWITTER = '#38A1F3',
  LIGHT_TWITTER = '#71C9F9'
}

export enum SPACING {
  NONE = '0',
  XSMALL2 = '.25rem',
  XSMALL = '.5rem',
  SMALL = '.75rem',
  MEDIUM = '1rem',
  LARGE = '1.25rem',
  XLARGE = '1.5rem',
  XLARGE2 = '2rem',
  XLARGE3 = '2.5rem',
  XLARGE4 = '3rem'
}

export enum FONT_SIZE {
  XSMALL = '.75rem',
  SMALL = '.875rem',
  MEDIUM = '1rem',
  LARGE = '1.125rem',
  XLARGE = '1.25rem',
  XLARGE2 = '1.5rem',
  XLARGE3 = '1.875rem',
  XLARGE4 = '2.25rem',
  XLARGE5 = '3rem'
}

export enum SPACING_TYPE {
  MARGIN = 'margin',
  PADDING = 'padding'
}

export const composeSpacing = (
  type: SPACING_TYPE,
  top: SPACING | undefined,
  right: SPACING | undefined,
  bottom: SPACING | undefined,
  left: SPACING | undefined,
  horizontal: SPACING | undefined,
  vertical: SPACING | undefined,
  around: SPACING | undefined
) => css`
  ${
    (top || vertical || around) && type === SPACING_TYPE.MARGIN
      ? {
          marginTop: top || vertical || around
        }
      : { paddingTop: top || vertical || around }
  }
  ${
    (right || horizontal || around) && type === SPACING_TYPE.MARGIN
      ? {
          marginRight: right || horizontal || around
        }
      : { paddingRight: right || horizontal || around }
  }
  ${
    (bottom || vertical || around) && type === SPACING_TYPE.MARGIN
      ? {
          marginBottom: bottom || vertical || around
        }
      : { paddingBottom: bottom || vertical || around }
  }
  ${
    (left || horizontal || around) && type === SPACING_TYPE.MARGIN
      ? {
          marginLeft: left || horizontal || around
        }
      : { paddingLeft: left || horizontal || around }
  }
`;


const borders = {
  default: `1px solid ${COLORS.LIGHT_GREY}`,
  black: `2px solid ${COLORS.BLACK}`,
  darkGrey: `2px solid ${COLORS.DARK_GREY}`,
  error: `2px solid ${COLORS.RED}`
};

const focus = `0 0 0 3px ${COLORS.LIGHT_GREY}`;

const theme = {
  borders,
  focus
};

export default theme;
