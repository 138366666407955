import * as React from 'react';
import styled from 'styled-components';
import { SPACING, ALIGN_CONTENT, SPACING_TYPE,composeSpacing, SCREEN_SIZES } from 'global/constants';

export interface IRowProps {
  centerContentHorizontal?: boolean;
  className?: string;
  style?: React.CSSProperties;
  paddingAround?: SPACING;
  paddingVertical?: SPACING;
  paddingHorizontal?: SPACING;
  paddingTop?: SPACING;
  paddingLeft?: SPACING;
  paddingBottom?: SPACING;
  paddingRight?: SPACING;
  onClick?: () => any;
}

export const Row: React.FC<IRowProps> = props => {
  return (
    <div data-test="row" style={props.style} onClick={props.onClick} className={props.className}>
      {props.children}
    </div>
  );
};

export const StyledRow = styled(Row)`
  display: flex;
  justify-content: ${props => props.centerContentHorizontal && ALIGN_CONTENT.CENTER};
  ${props =>
    composeSpacing(
      SPACING_TYPE.PADDING,
      props.paddingTop && props.paddingTop,
      props.paddingRight && props.paddingRight,
      props.paddingBottom && props.paddingBottom,
      props.paddingLeft && props.paddingLeft,
      props.paddingHorizontal && props.paddingHorizontal,
      props.paddingVertical && props.paddingVertical,
      props.paddingAround && props.paddingAround
    )}
    @media only screen and (max-width: ${SCREEN_SIZES.SMALL}){
      flex-wrap: wrap;
    }
`;

export default StyledRow;
