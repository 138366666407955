import React from "react";
import { blogPosts } from 'content';
import Wrapper from 'components/Wrapper';
import { Row, Col } from 'components/Grid';
import { SPACING } from "global/constants";

interface BlogPost {
  title: string,
  date: string,
  content: string
}

function BlogFeed({ className }: any) {
  return (
    <Wrapper>
      <Row paddingTop={SPACING.XLARGE}>
        <Col>
          {Object.values(blogPosts).map((item: BlogPost) => <div>
            <h2>{item.title}</h2>
            <h3>{item.date}</h3>
            <p>{item.content}</p>
          </div>)}
        </Col>
      </Row>
    </Wrapper>
  )
}

export default BlogFeed;