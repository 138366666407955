// Modules
export const BrainSeed = require('content/json/BrainSeed.json');
export const Crossfold = require('content/json/Crossfold.json');
export const LaunchCodes = require('content/json/LaunchCodes.json');
export const LaunchCodesExpander = require('content/json/LaunchCodesExpander.json');
export const SubRing = require('content/json/SubRing.json');
export const v3kt = require('content/json/v3kt.json');

// Blog Posts
export const ImplantingBrainSeed = require('content/json/ImplantingBrainSeed.json');

export const modules = {
  BrainSeed,
  Crossfold,
  LaunchCodes,
  LaunchCodesExpander,
  SubRing,
  v3kt
}

export const blogPosts = {
  ImplantingBrainSeed
}
