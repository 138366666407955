import styled from 'styled-components';
import { COLORS } from 'global/constants'

const StyledModuleHeader = styled.h1`
  color: ${COLORS.LIGHT_GREY};
  border-bottom: 1px solid white;
  width: 100%;
  font-size: 2rem;
  line-height: 3rem;
`

export default StyledModuleHeader;

