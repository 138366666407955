import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { COLORS } from 'global/constants';
import { modules } from 'content';
import Wrapper from './Wrapper';

function Header({ className }: any) {
  const [isOpen, setIsOpen] = useState(false);
  return(
    <nav style={{display:'flex', 'flexDirection': 'column'}}>
      <div className={className}>
        <StyledMenu isOpen={isOpen} setIsOpen={setIsOpen}/>
      </div>
      {isOpen && <StyledSubMenu>
        <Wrapper>
          <div style={{ display: 'flex', justifyContent: 'center'}}>
            {Object.values(modules).map((item: any) => <NavLink to={`/modules/${item.url}`} style={{ padding: '.5rem 1rem', textTransform: 'uppercase', fontSize: '13px' }}>{item.name}</NavLink>)}
          </div>
        </Wrapper>
    </StyledSubMenu>}
    </nav>
  )
}

function Menu({ className, isOpen, setIsOpen }: any){
  return(
    <div className={className}>
      <ul>
        <NavLink onClick={() => setIsOpen(false)} to='/'>Home</NavLink>
        <NavLink onClick={() => setIsOpen(false)} to='/about-us'>About Us</NavLink>
        <StyledLi onClick={() => {setIsOpen(!isOpen)}}>Modules <FontAwesomeIcon size="xs" icon={faChevronDown}/></StyledLi>
      </ul>
    </div>
  )
}

const StyledLi = styled.li`
  cursor: pointer;
`;

const StyledSubMenu = styled.div`
  background: ${COLORS.DARK_GREY};
  height: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  a, a:visited {
    color: ${COLORS.MEDIUM_GREY}
  }
  a.active {
    color: ${COLORS.LIGHT_GREY}
  }
`;

const StyledMenu = styled(Menu)`
  text-transform: uppercase;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: 850px;
  ul {
    display: flex;
  }
  li, a {
    padding: 0 1rem;
  }
  a, a:visited {
    color: ${COLORS.MEDIUM_GREY}
  }
  a.active {
    color: ${COLORS.LIGHT_GREY}
  }
`

const StyledHeader = styled(Header)`
  background: #000;
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #7a7a7a;
  font-size: 14px;
`;

export default StyledHeader;