import React from 'react';
import { Row, Col } from './Grid';
import { SPACING, WIDTHS } from 'global/constants';
import ModuleHeader from './ModuleHeader';
import Wrapper from 'components/Wrapper';

interface Example {
  name: string,
  additional: string,
  settings: string,
  description: string,
  experiment: string
}

interface Troubleshooting {
  q: string,
  a: string
}

interface ModuleProps {
  className?: string,
  name: string,
  moduleImgPath: string,
  description: string,
  uses?: ''[],
  features?: ''[],
  videos?: ''[],
  manual?: string,
  examples?: Example[],
  troubleshooting?: Troubleshooting[],
}

function Module(props: ModuleProps){
  const { 
    name,
    moduleImgPath,
    description,
    uses,
    features,
    videos,
    manual,
    examples,
    troubleshooting
  } = props;
  const troubleshootingIdString = (item: Troubleshooting) => {
    const id = item.q?.toLowerCase().replace(/[^a-z ]/g, "").split(" ").join("-")
    return id;
  }
  const examplesIdString = (item: Example) => {
    const id = item.name?.toLowerCase().replace(/[^a-z ]/g, "").split(" ").join("-")
    return id;
  }
  return (
    <Wrapper>
      <Row paddingVertical={SPACING.LARGE}>
        <ModuleHeader>{name}</ModuleHeader>
      </Row>
      <Row>
        <Col paddingRight={SPACING.XLARGE} md={WIDTHS.HALF} xs={WIDTHS.FULL}>
          <img src={moduleImgPath} />
        </Col>
        <Col md={WIDTHS.HALF} xs={WIDTHS.FULL}>
          <p>{description}</p>
        </Col>
      </Row>
      {
       uses &&
        <Row paddingVertical={SPACING.LARGE}>
          <Col>
            <h2>Uses</h2>
            <ul>
            {uses.map((item: string) => <li>{item}</li>)}
            </ul>
          </Col>
        </Row>
        }
        {
        features &&
        <Row paddingVertical={SPACING.LARGE}>
          <Col>
            <h2>Features</h2>
            <ul>
            {features.map((item: string) => <li>{item}</li>)}
            </ul>
          </Col>
        </Row>
        }
        {
        manual &&
        <Row paddingVertical={SPACING.LARGE}>
          <Col>
            <h2>Manual</h2>
            <a href={manual}>{manual}</a>
          </Col>
        </Row>
        }
        {
         videos && 
          <Row paddingVertical={SPACING.LARGE}>
            <Col paddingRight={SPACING.XLARGE} md={WIDTHS.HALF} xs={WIDTHS.FULL}>
              <h2>Videos</h2>
              <ul>
              {videos.map((item: string) =>
                <li><iframe width="560" height="315" src={item} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe></li>
                )}
              </ul>
            </Col>
          </Row>
        }
        {
         examples && 
          <Row paddingVertical={SPACING.LARGE}>
            <Col xs={WIDTHS.FULL}>
              <h2>Examples</h2>
              <div style={{ display: 'flex', flexDirection: 'column', padding: '.5rem 0'}}>
                {examples.map((item: any) => <a style={{lineHeight: '1.5rem'}} href={`#${examplesIdString(item)}`}>{item.name}</a>)}
              </div>
              {examples.map((item: Example) =>
                <div style={{ padding: '.5rem 0'}}><h3 id={examplesIdString(item)}>{item.name}</h3>
                  <p>Additional: {item.additional}</p>
                  <p>Settings: {item.settings}</p>
                  <p>Description: {item.description}</p>
                  <p>Experiment: {item.experiment}</p>
                </div>
                )}
            </Col>
          </Row>
        }
        {
         troubleshooting && 
          <Row paddingVertical={SPACING.LARGE}>
            <Col xs={WIDTHS.FULL}>
              <h2>Troubleshooting</h2>
              <div style={{ display: 'flex', flexDirection: 'column', padding: '.5rem 0'}}>
                {troubleshooting.map((item: any) => <a style={{lineHeight: '1.5rem'}} href={`#${troubleshootingIdString(item)}`}>{item.q}</a>)}
                </div>
              {troubleshooting.map((item: Troubleshooting) =>
                <div id={troubleshootingIdString(item)} style={{ padding: '.5rem 0'}}><h3>{item.q}</h3>
                <p>{item.a}</p></div>
                )}
            </Col>
          </Row>
        }
    </Wrapper>
  )
}

export default Module;