import React from 'react';
import Wrapper from 'components/Wrapper';
import { Row, Col } from 'components/Grid';
import { SPACING } from 'global/constants';

function About(){
  return(
    <Wrapper>
      <Row paddingVertical={SPACING.XLARGE}>
        <Col>
          <p>
            Antimatter Audio is a musical instrument manufacturer located in Portland, OR, USA. To contact us, send email to antimatteraudio at gmail dot com.
          </p>
        </Col>
      </Row>
    </Wrapper>
  )
}

export default About;