import React from 'react';
import Header from 'components/Header';
import Home from 'pages/Home';
import About from 'pages/About';
import Module from 'components/Module';
import { modules } from 'content';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

function App() {
  return (
    <Router>
      <Header/>
      <Switch>
        <Route exact path="/" component={Home}/>
        <Route exact path="/about-us" component={About}/>
          {Object.values(modules).map((item: any) => {
          return <Route path={`/modules/${item.url}/`}>
            <Module 
              name={item.name}
              moduleImgPath={`${process.env.PUBLIC_URL}/assets/${item.moduleImgPath}`}
              description={item.description}
              uses={item.uses}
              features={item.features}
              videos={item.videos}
              manual={item.manual}
              examples={item.examples}
              troubleshooting={item.troubleshooting}
            />
        </Route>})}
      </Switch>
    </Router>
  );
}

export default App;
