import * as React from 'react';
import styled from 'styled-components';
import { SPACING, SCREEN_SIZES, WIDTHS, ALIGN_CONTENT, DISPLAY, SPACING_TYPE, composeSpacing } from 'global/constants';

export interface IColProps {
  xs?: WIDTHS;
  sm?: WIDTHS;
  md?: WIDTHS;
  lg?: WIDTHS;
  xl?: WIDTHS;
  paddingAround?: SPACING;
  paddingVertical?: SPACING;
  paddingHorizontal?: SPACING;
  paddingTop?: SPACING;
  paddingLeft?: SPACING;
  paddingBottom?: SPACING;
  paddingRight?: SPACING;
  centerContentHorizontal?: boolean;
  centerContentVertical?: boolean;
  alignContentRight?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

export const Col: React.FC<IColProps> = props => {
  return (
    <div data-test="col" className={props.className}>
      {props.children}
    </div>
  );
};

export const StyledCol = styled(Col)`
  display: ${props => props.alignContentRight && DISPLAY.FLEX};
  justify-content: ${props => props.alignContentRight && ALIGN_CONTENT.FLEX_END};
  flex-basis: ${props => props.xs};
  max-width: ${props => props.xs};
  ${props =>
    composeSpacing(
      SPACING_TYPE.PADDING,
      props.paddingTop && props.paddingTop,
      props.paddingRight && props.paddingRight,
      props.paddingBottom && props.paddingBottom,
      props.paddingLeft && props.paddingLeft,
      props.paddingHorizontal && props.paddingHorizontal,
      props.paddingVertical && props.paddingVertical,
      props.paddingAround && props.paddingAround
    )}
  @media screen and (min-width: ${SCREEN_SIZES.SMALL}) {
    flex-basis: ${props => props.sm};
    max-width: ${props => props.sm};
  }
  @media screen and (min-width: ${SCREEN_SIZES.MEDIUM}) {
    flex-basis: ${props => props.md};
    max-width: ${props => props.md};
  }
  @media screen and (min-width: ${SCREEN_SIZES.LARGE}) {
    flex-basis: ${props => props.lg};
    max-width: ${props => props.lg};
  }
  @media screen and (min-width: ${SCREEN_SIZES.XLARGE}) {
    flex-basis: ${props => props.xl};
    max-width: ${props => props.xl};
  }
`;

export default StyledCol;
